<template>
  <div class="reduced-commissions-dialog">
    <div class="reduced-commissions-dialog__header">
      <div class="reduced-commissions-dialog__title">
        Снижаем <br>
        комиссии!
      </div>
      <div class="reduced-commissions-dialog__subtitle">Пополнение кабинетов*</div>
      <img src="@/assets/images/reduced-commissions/rocket.png" class="reduced-commissions-dialog__header-decor">
    </div>
    <div class="reduced-commissions-dialog__body">
      <div class="reduced-commissions-dialog__items">
        <div class="reduced-commissions-dialog__item">
          <div class="reduced-commissions-dialog__item-platform">
            <img src="@/assets/images/reduced-commissions/google.png">
          </div>
          <div class="reduced-commissions-dialog__item-name">Google Ads</div>
          <div class="reduced-commissions-dialog__item-price">
            <img src="@/assets/images/reduced-commissions/star.svg" alt="">
            <span>0.99%</span>
          </div>
        </div>
        <div class="reduced-commissions-dialog__item">
          <div class="reduced-commissions-dialog__item-platform">
            <img src="@/assets/images/reduced-commissions/telegram.png">
          </div>
          <div class="reduced-commissions-dialog__item-name">Telegram Ads</div>
          <div class="reduced-commissions-dialog__item-price">
            <img src="@/assets/images/reduced-commissions/star.svg" alt="">
            <span>9.99%</span>
          </div>
        </div>
      </div>
      <div>
        <ButtonElement text="Создать Бизнес аккаунт" class="reduced-commissions-dialog__button" @click-button="addAccount"/>
      </div>
      
     <div class="reduced-commissions-dialog__footer">
      <div class="reduced-commissions-dialog__footer-item">*<a href="/docs/Promotion_Rules_Rules_Lowering _commissions.pdf" target="_blank">Полные Правила акции</a></div>
      <div class="reduced-commissions-dialog__footer-item">Предложение действует до 26.11.23</div>
     </div>
    </div>
  </div>
</template>
  
<script>
import ButtonElement from '../../components/elements/ButtonElement'

export default {
  name: 'ReducedСommissions',
  components: {
    ButtonElement,
  },
  methods: {
    addAccount() {
      this.$store.dispatch('Dialog/closeDialog')

      setTimeout(() => {
        this.$store.commit('Dialog/setData', { label: 'isEditable', data: false })
        this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'BusinessAccountData' })
        this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
      }, 300);
    },
  }
}
</script>
  
<style scoped lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.reduced-commissions-dialog {
  @include below(768px) {
    margin-top: 10vh;
    margin-bottom: 5vh;
  }

  &__header {
    background: #232327;
    padding: 4rem;
    position: relative;

    @include below(768px) {
      padding: 4rem 2rem;
    }
  }

  &__header-decor {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(0, -35px);

    @include below(768px) {
      display: none;
    }
  }

  &__title {
    color: #fff;
    font-size: 6.4rem;
    font-style: normal;
    font-weight: 700;
    line-height: 70%;
    letter-spacing: -0.375px;
    margin-bottom: 2rem;

    @include below(768px) {
      font-size: 4.4rem;
    }
  }

  &__subtitle {
    font-size: 2.8rem;
    font-weight: 700;
    line-height: 80%;
    letter-spacing: -0.375px;
    color: #BFC2C6;

    @include below(768px) {
      font-size: 2rem;
    }
  }

  &__body {
    padding: 4rem;

    @include below(768px) {
      padding: 4rem 2rem;
    }
  }

  &__items {
    margin-bottom: 4rem;
  }

  &__item {
    display: flex;
    align-items: center;

    @include below(768px) {
      justify-content: space-between;
      /* flex-direction: column;
      text-align: center;
      gap: 2rem; */
    }

    & + .reduced-commissions-dialog__item {
      padding-top: 1.6rem;
      margin-top: 1.6rem;
      border-top: 1px solid rgba(156, 175, 241, .5);
    }
  }

  &__item-platform {
    flex-shrink: 0;
    margin-right: 1.6rem;

    @include below(768px) {
      width: 40px;

      img {
        max-width: 100%;
      }
    }
  }

  &__item-name {
    color: #333;
    font-size: 2.8rem;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    width: 25rem;
    max-width: 100%;

    @include below(768px) {
      width: 100%;
      font-size: 2rem;
    }
  }

  &__item-price {
    position: relative;

    img {
      @include below(768px) {
       width: 80px;
      }
    }

    span {
      color: #FFF;
      font-size: 2.8rem;
      font-weight: 700;
      line-height: normal;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-wrap: nowrap;

      @include below(768px) {
       font-size: 2rem;
      }
    }
  }

  &__button {
    background: #232327 !important;
    border-color: #232327;
    width: 100%;
    max-width: 28rem;
    height: 5rem;

    @include below(768px) {
      max-width: 100%;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 3.2rem;

    @include below(768px) {
      flex-direction: column;
      align-items: center;
      gap: 2rem;
    }
  }

  &__footer-item {
    color: #9BA2AB;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: normal;

    a {
      text-decoration-line: underline;
    }
  }
}
</style>
  